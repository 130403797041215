import ApiService, { Params } from '@/api/ApiService';

export default {
  findOne(id: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/submissions/${id}`, config);
  },
  start(id: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.post(`/submissions/${id}/start`, {}, config);
  },
  finish(id: string, payload: any, params: Params) {
    const config = {
      skipAuthRefresh: true,
      params,
    };
    return ApiService.post(`/submissions/${id}/finish`, payload, config);
  },
  partial(surveyId: string, payload: any) {
    const config = {
      skipAuthRefresh: true,
    };
    return ApiService.post(`/submissions/${surveyId}/partial`, payload, config);
  },
  runPartial(surveyId: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.post(`/submissions/${surveyId}/partial/run`, {}, config);
  },
};
