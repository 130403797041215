import { I18nOptions, createI18n } from 'vue-i18n';

import de from '@/locales/de.json';
import en from '@/locales/en.json';
import es from '@/locales/es.json';
import fr from '@/locales/fr.json';
import it from '@/locales/it.json';
import nl from '@/locales/nl.json';
import pt from '@/locales/pt.json';
import ar from '@/locales/ar.json';
import cs from '@/locales/cs.json';
import el from '@/locales/el.json';
import fa from '@/locales/fa.json';
import he from '@/locales/he.json';
import id from '@/locales/id.json';
import ja from '@/locales/ja.json';
import ko from '@/locales/ko.json';
import pl from '@/locales/pl.json';
import ru from '@/locales/ru.json';
import tr from '@/locales/tr.json';
import uk from '@/locales/uk.json';
import vi from '@/locales/vi.json';
import zh from '@/locales/zh.json';

export type MessageSchema = typeof en;

de satisfies MessageSchema;
es satisfies MessageSchema;
fr satisfies MessageSchema;
it satisfies MessageSchema;
nl satisfies MessageSchema;
pt satisfies MessageSchema;
ar satisfies MessageSchema;
cs satisfies MessageSchema;
el satisfies MessageSchema;
fa satisfies MessageSchema;
he satisfies MessageSchema;
id satisfies MessageSchema;
ja satisfies MessageSchema;
ko satisfies MessageSchema;
pl satisfies MessageSchema;
ru satisfies MessageSchema;
tr satisfies MessageSchema;
uk satisfies MessageSchema;
vi satisfies MessageSchema;
zh satisfies MessageSchema;

export const i18nOptions: I18nOptions = {
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    de,
    en,
    es,
    fr,
    it,
    nl,
    pt,
    ar,
    cs,
    el,
    fa,
    he,
    id,
    ja,
    ko,
    pl,
    ru,
    tr,
    uk,
    vi,
    zh,
  },
};

export const i18n = createI18n(i18nOptions);

declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}
}
