import { RouteRecordRaw } from 'vue-router';
import SurveyDashboardResultsRoute from './ResultsRoute';

const SurveyDashboardRoutes: RouteRecordRaw[] = [
  {
    path: 'setup',
    name: 'setup',
    component: () =>
      import('@/views/SurveyDashboard/BuilderTab/BuilderPage.vue'),
    meta: {
      name: 'Survey Setup',
      authorize: true,
      user: true,
    },
  },
  SurveyDashboardResultsRoute,
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import('@/views/SurveyDashboard/SettingsTab/SettingsPage.vue'),
    meta: {
      name: 'Survey Settings',
      authorize: true,
      user: true,
    },
  },
];

export default SurveyDashboardRoutes;
