import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import Auth from '@/api/endpoints/Auth';
import { useUserStore } from '@/stores/user';
import pinia from '@/stores';
import scrollBehavior from './scrollBehavior';

const router = createRouter({
  routes,
  linkActiveClass: 'active',
  history: createWebHistory(),
  scrollBehavior,
});

router.beforeEach((to, from) => {
  const isFromPreviewResults = from.meta?.shareableLink;
  const previewTo = `preview-${to.name?.toString()}`;
  if (isFromPreviewResults && router.hasRoute(previewTo)) {
    return { ...to, name: previewTo };
  }
});

router.beforeEach(async (to, _, next) => {
  const isAuthRoute = to?.meta?.auth;
  const isAuthorizeRoute = to?.meta?.authorize;

  const store = useUserStore(pinia);

  const isAuthenticated = store.isAuthenticated;

  if (isAuthRoute && isAuthenticated) {
    try {
      await Auth.refreshToken();
      next({ name: 'surveys' });
    } catch {
      store.signOut();
    }
  } else if (isAuthorizeRoute) {
    if (isAuthenticated) {
      next();
    } else {
      next({ name: 'sign-in' });
    }
  } else {
    next();
  }
});

router.beforeEach((to, _, next) => {
  const isAdminRoute = to.meta?.admin;
  const userStore = useUserStore();
  if (isAdminRoute && !userStore.isAdmin) {
    next({ name: 'surveys' });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (
    to?.meta?.keepQueryParams &&
    !!Object.keys(from.query).length &&
    !Object.keys(to.query).length
  ) {
    next({ ...to, query: from.query });
  } else {
    next();
  }
});

router.afterEach(() => {
  window.Intercom?.('update');
});

export default router;
