export const defaultFinishLink = 'https://opinionx.co/survey-finished' as const;

export enum AnswerType {
  OTHER_ANSWERS_ONLY = 'other_answers_only',
  OTHER_ANSWERS_EXCLUDED = 'other_answers_excluded',
}

export enum ProfileRankType {
  CATEGORY = 'profile_category_type',
  OPTION = 'profile_option_type',
}
